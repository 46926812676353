const hostName = "https://drop-x.ru";

// Reusable fetch handler
async function fetchRequest(url, options) {
  try {
    const response = await fetch(url, options);
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (e) {
    console.error(`Error with fetch request to ${url}:`, e);
    throw e;
  }
}

// Helper function for common POST requests
async function postData(url, data) {
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchRequest(url, options);
}

// Helper function for common GET requests
async function getData(url) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchRequest(url, options);
}

async function putDataToDataBase(data) {
  const url = `${hostName}/api/v1/vamsvet/putDataToDataBase`;
  return postData(url, data);
}

async function getSellerPropertiesFromDataBase() {
  const url = `${hostName}/api/v1/vamsvet/getSellerPropertiesFromDataBase`;
  const response = await getData(url);
  return response?.data || [{ id: 1, value: "No data", title: "No data" }];
}

async function putSegmentInfoToDataBase(data) {
  const url = `${hostName}/api/v1/vamsvet/putSegmentInfoToDataBase`;
  return postData(url, data);
}

async function getSegmentInfoData() {
  const url = `${hostName}/api/v1/vamsvet/getSegmentInfoData`;
  const response = await getData(url);
  return response?.data || [];
}

async function getSendedInfoData(
  rowNumber,
  article,
  brand,
  desission,
  priority
) {
  const url = `${hostName}/api/v1/vamsvet/getSendedInfoData?rowNumber=${rowNumber}&article=${article}&brand=${brand}&desission=${desission}&priority=${priority}`;
  const response = await getData(url);
  return { data: response?.data || [], total: response?.total || 0 };
}

async function getSendedLog(rowNumber, article, brand, desission, priority) {
  const url = `${hostName}/api/v1/vamsvet/getSendedLog?rowNumber=${rowNumber}&article=${article}&brand=${brand}&desission=${desission}&priority=${priority}`;
  const response = await getData(url);
  return { data: response?.data || [], total: response?.total || 0 };
}

async function sendDataToVamsvetServer() {
  const url = `${hostName}/api/v1/vamsvet/sendDataToVamsvetServer`;
  const result = await getData(url);
  return result;
}

async function login(userinfo) {
  const url = `${hostName}/api/authorization/login`;
  return postData(url, userinfo);
}

async function checkAuth(token) {
  const url = `${hostName}/api/authorization/protected`;
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(url, options);
  return response.ok; // Returns true if the status is OK
}

async function getBrandList() {
  const url = `${hostName}/api/v1/vamsvet/getBrandList`;
  const response = await getData(url);
  return response?.brandList || [" - "];
}

async function getSegmentList(segment_name) {
  const url = `${hostName}/api/v1/vamsvet/getSegmentList?segment_name=${encodeURIComponent(
    segment_name
  )}`;
  return (await getData(url)) || [" - "];
}

async function updateSegment(segmentArray) {
  const url = `${hostName}/api/v1/vamsvet/updateSegment`;
  return postData(url, segmentArray);
}

async function getAllInfoAboutSku(sku) {
  const url = `${hostName}/api/v2/vamsvet/getAllInfoAboutSku?sku=${sku}`;
  return (
    getData(url) || {
      whatPrice: "Loading...",
      sellerInfo: {
        luftOfCP: "Loading...",
        numOfPositivConclusions: "Loading...",
      },
      unitInfo: { unit: "Loading..." },
      decisions: { priceDecizion: "Loading..." },
      solvedValues: { fineshedPrice: "Loading..." },
    }
  );
}

module.exports = {
  putDataToDataBase,
  getSellerPropertiesFromDataBase,
  putSegmentInfoToDataBase,
  getSegmentInfoData,
  getSendedInfoData,
  sendDataToVamsvetServer,
  getBrandList,
  login,
  checkAuth,
  getSegmentList,
  updateSegment,
  getAllInfoAboutSku,
  getSendedLog,
};
